<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card class="cardClassDetailForm" title="기본정보">
          <template slot="card-button">
            <q-btn-group outline >
                <c-btn 
                  v-if="editable&&isOld" 
                  label="삭제" 
                  icon="delete"
                  @btnClicked="deleteTarget" />
                <c-btn 
                  v-if="editable" 
                  :isSubmit="isSave"
                  :url="saveUrl"
                  :param="target"
                  :mappingType="mappingType"
                  label="저장" 
                  icon="save"
                  @beforeAction="saveTarget"
                  @btnCallback="saveTargetCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-plant
                :required="true"
                :editable="editable"
                name="plantCd"
                v-model="target.plantCd">
              </c-plant>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-text
                :required="true"
                :editable="editable"
                label="추진부서"
                name="propelDeptName"
                v-model="target.propelDeptName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-datepicker
                :required="true"
                :editable="editable"
                type="year"
                default="today"
                label="대상년도"
                name="targetYear"
                v-model="target.targetYear"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-dept 
                :required="true" 
                :editable="editable" 
                type="edit" 
                label="작성부서" 
                name="writeDeptCd" 
                v-model="target.writeDeptCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-datepicker
                :required="true"
                default="today"
                label="작성일"
                name="writeDt"
                v-model="target.writeDt"
              />
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="안전보건 목표 및 추진계획/실적"
          :merge="grid.merge"
          :columns="grid.columns"
          :data="target.plans"
          :gridHeight="gridHeight"
          :editable="editable"
          :usePaging="false"
          :isExcelDown="true"
          :filtering="false"
          :isFullScreen="true"
          :columnSetting="false"
          selection="multiple"
          rowKey="kpiTargetPlanId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addTargetPlan"/>
              <c-btn v-if="editable" label="제외" icon="remove" @btnClicked="deleteTargetPlan" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'custom' && props.row.editFlag !== 'C'">
              <!-- 
                1. 실적이 들어가지 않은경우
                - 입력하기
                2. 실적이 들어간 경우
                - ㅁ건 
              -->
              <template v-if="props.row.results && props.row.results.length > 0">
                  <!-- flat  -->
                <q-btn 
                  dense
                  outline 
                  color="blue-9"
                  style="font-weight:700"
                  :label="`${props.row.results.length}건`" 
                  @click="openResult(props.row)" />
              </template>
              <template v-else>
                <q-btn 
                  dense
                  outline  
                  color="green-9"
                  style="font-weight:700"
                  label="입력하기" 
                  @click="openResult(props.row)" />
              </template>
            </template>
          </template>
        </c-table>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'kpi-target-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        kpiTargetId: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      target: {
        kpiTargetId: '',  // 안전보건목표 일련번호
        plantCd: null,  // 사업장
        propelDeptName: '',  // 추진부서
        targetYear: '',  // 대상년도
        writeDeptCd: '',  // 작성부서
        writeDt: '',  // 작성일
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        plans: [],
        deletePlans: [],
      },
      grid: {
        merge: [
          { index: 0, colName: "kpiMstId" },
        ],
        columns: [
          {
            name: 'kpiTypeName',
            field: 'kpiTypeName',
            label: '구분',
            align: 'center',
            style: 'width: 200px',
            sortable: false,
            fix: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '해당부서',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
            fix: true,
          },
          {
            name: 'kpi',
            field: 'kpi',
            label: 'KPI<br/>성과지표',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
            fix: true,
          },
          {
            name: 'kpiCalculation',
            field: 'kpiCalculation',
            label: '산정식',
            align: 'left',
            style: 'width: 200px',
            sortable: false,
            fix: true,
          },
          {
            required: true,
            name: 'performanceGoal',
            field: 'performanceGoal',
            label: '성과목표',
            align: 'center',
            style: 'width: 100px',
            type: 'number',
            sortable: false,
            fix: true,
          },
          {
            required: true,
            name: 'performanceUnit',
            field: 'performanceUnit',
            label: '단위',
            align: 'center',
            style: 'width: 80px',
            type: 'text',
            sortable: false,
            fix: true,
          },
          {
            name: 'schedule',
            field: 'schedule',
            label: '추진일정',
            child: [
              {
                name: 'month1',
                field: 'month1',
                label: '1월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month2',
                field: 'month2',
                label: '2월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month3',
                field: 'month3',
                label: '3월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month4',
                field: 'month4',
                label: '4월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month5',
                field: 'month5',
                label: '5월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month6',
                field: 'month6',
                label: '6월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month7',
                field: 'month7',
                label: '7월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month8',
                field: 'month8',
                label: '8월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month9',
                field: 'month9',
                label: '9월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month10',
                field: 'month10',
                label: '10월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month11',
                field: 'month11',
                label: '11월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
              {
                name: 'month12',
                field: 'month12',
                label: '12월',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                'true': 'Y',
                'false': 'N',
                color: 'blue', 
              },
            ]
          },
          {
            name: 'result',
            field: 'result',
            label: '성과',
            child: [
              {
                name: 'status',
                field: 'status',
                label: '현황',
                align: 'center',
                style: 'width: 80px',
                sortable: false,
                type: 'text',
              },
              {
                name: 'achievementRate',
                field: 'achievementRate',
                label: '달성율(%)',
                align: 'center',
                style: 'width: 80px',
                sortable: false,
                type: 'number',
              },
            ],
          },
          // {
          //   name: 'budget',
          //   field: 'budget',
          //   label: '예산(만원)',
          //   align: 'center',
          //   style: 'width: 120px',
          //   sortable: false,
          //   type: 'number',
          // },
          {
            name: 'performanceDetails',
            field: 'performanceDetails',
            label: '실적내용',
            align: 'left',
            style: 'width: 200px',
            sortable: false,
            type: 'textarea',
          },
          {
            name: 'custom',
            field: 'custom',
            label: '상세실적',
            align: 'center',
            style: 'width: 80px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            style: 'width: 200px',
            sortable: false,
            type: 'textarea',
          },
        ],
        data: [],
      },
      planRow: null,
      editable: false,
      isSave: false,
      detailUrl: '',
      saveUrl: '',
      deleteUrl: '',
      mappingType: 'PUT',
      popupOptions: {
        isFull: false,
        width: '50%',
        target: null,
        title: '',
        visible: false,
        top: '50px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return String(this.contentHeight - 180) + 'px';
    },
    isOld() {
      return Boolean(this.popupParam.kpiTargetId)
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.kpi.target.get.url;
      this.saveUrl = transactionConfig.kpi.target.insert.url;
      this.deleteUrl = transactionConfig.kpi.target.delete.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.kpiTargetId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.$_.extend(this.target, _result.data)
        },);
      } else {
        // 신규등록 
        this.$set(this.target, 'writeDeptCd', this.$store.getters.user.deptCd)
        this.$set(this.target, 'writeDt', this.$comm.getToday())
      }
    },
    addTargetPlan() {
      this.popupOptions.title = '안전보건 목표 및 세부추진사항 목록';
      this.popupOptions.target = () => import(`${'./kpiMstPopup.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeTargetPopup;
    },
    closeTargetPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          let _idx = this.$_.findIndex(this.target.plans, { kpiMstDetailId: item.kpiMstDetailId })
          _idx = _idx > -1 ? _idx : 0;
          this.target.plans.splice(_idx, 0, {
            kpiTargetPlanId: uid(),  // 안전보건목표 계획 일련번호
            kpiTargetId: this.popupParam.kpiTargetId,  // 안전보건목표 일련번호
            kpiMstDetailId: item.kpiMstDetailId,  // 안전보건목표 세부추진사항 일련번호
            kpiMstId: item.kpiMstId,  // 안전보건목표 마스터 일련번호
            detailName: item.detailName,  // 세부추진사항
            deptName: item.deptName,  // 해당부서
            kpiTypeName: item.kpiTypeName,
            kpiCalculation: item.kpiCalculation,
            kpi: item.kpi,  // kpi 성과지표
            performanceGoal: '',  // 성과목표
            status: '',  // 현황
            achievementRate: 0,  // 달성율
            budget: 0,  // 예산
            performanceDetails: '',  // 실적내용
            remark: '',  // 비고
            month1: 'N',  // 1월 계획
            month2: 'N',  // 2월 계획
            month3: 'N',  // 3월 계획
            month4: 'N',  // 4월 계획
            month5: 'N',  // 5월 계획
            month6: 'N',  // 6월 계획
            month7: 'N',  // 7월 계획
            month8: 'N',  // 8월 계획
            month9: 'N',  // 9월 계획
            month10: 'N',  // 10월 계획
            month11: 'N',  // 11월 계획
            month12: 'N',  // 12월 계획
            regUserId: this.$store.getters.user.userId,  // 등록자
            editFlag: 'C'
          })
        })
      }
    },
    deleteTargetPlan() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '제외하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            if (!this.target.deletePlans) this.target.deletePlans = [];
            this.$_.forEach(selectData, item => {
              if (item.editFlag !== 'C'
                && this.$_.findIndex(this.target.deletePlans, { kpiTargetPlanId: item.kpiTargetPlanId })) {
                  this.target.deletePlans.push(item)
              }
              this.target.plans = this.$_.reject(this.target.plans, item);
            })
            this.$refs['table'].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    openResult(row) {
      if (!row) return;
      this.$set(this.$data, 'planRow', row)
      this.popupOptions.title = '[' + row.kpi + '] 상세 실적';
      this.popupOptions.param = {
        kpiTargetId: row.kpiTargetId,
        kpiTargetPlanId: row.kpiTargetPlanId,
      };
      this.popupOptions.target = () => import(`${'./kpiTargetDetailPopup.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeResultPopup;
    },
    closeResultPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (result && result.col1) {
        this.$set(this.planRow, 'results', result.col1)
      }
    },
    saveTarget() {
      if (this.isOld) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.kpi.target.update.url;
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.kpi.target.insert.url;
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.target.regUserId = this.$store.getters.user.userId
              this.target.chgUserId = this.$store.getters.user.userId
      
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveTargetCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.kpiTargetId = result.data
      this.getDetail();
    },
    deleteTarget() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.kpiTargetId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>